import * as React from 'react';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

// markup
const NotFoundPage = () => (
  <main style={pageStyles}>
    <title>Not found</title>
  </main>
);

export default NotFoundPage;
